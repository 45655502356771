// @import './_variables';  Imported in site.scss
@import './uikit';
@import './modules/heights';
@import './modules/widths';
@import './modules/margins';
@import './modules/borders';
@import './modules/calendar';
@import './modules/flex';
@import './modules/paddings';
@import './modules/rotations';
@import './modules/text';
@import './modules/chat';
@import './modules/counter';
@import './modules/iframes';
@import './modules/rating';
@import './modules/footer';
@import './modules/tom_select';

// BACKGOUND IMAGES

.main-bg {
@if $site-background-image != 'null' {
    background-image: url($site-background-image);
  } @else {
    background-color: var(--main-bg-color, $global-background);
  }
}

@media (pointer: coarse) {
  .main-bg.uk-background-fixed {
    background-attachment: fixed;
  }
}

a{font-weight: bold;}

.tox .tox-tinymce-aux {
  z-index: 1020;
}

@media print {
  .no-print {
    display: none;
  }
}
