$django-site-backgroundimage: "/var/www/media/CACHE/images/uploads/sites/wishtobethere/bg/09c560d9b6238e909eac450f6c8d738f.jpg?2025-03-24T10:10:39.688988+00:00";
                  $django-header-backgroundimage: "/var/www/media/CACHE/images/uploads/sites/wishtobethere/header_bg/716a8425cec013f2fe7562274f6b7aeb.jpg?2025-03-24T10:10:39.688988+00:00";
                  $django-color: #2515f5;
                  $django-primary-color: #ffffff;
                  $django-secondary-color: #dddddd;
                  $django-inverse-color: #ffffff;
                  $django-emphasis-color: #3224f5;
                  $django-muted-color: #eeeeee;


@font-face {
    font-family: 'Gelasio';
    src: url('/var/www/media/uploads/fonts/gelasio-regular_ZgWdbav.woff2') format('woff2'),
        url('/var/www/media/uploads/fonts/gelasio-regular_ImhZFuG.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Gelasio';
    src: url('/var/www/media/uploads/fonts/gelasio-bold_XB3eLxj.woff2') format('woff2'),
        url('/var/www/media/uploads/fonts/gelasio-bold_63ElpSI.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Gelasio';
    src: url('/var/www/media/uploads/fonts/gelasio-bolditalic_HaRm4Li.woff2') format('woff2'),
        url('/var/www/media/uploads/fonts/gelasio-bolditalic_3ddHJMd.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Gelasio';
    src: url('/var/www/media/uploads/fonts/gelasio-italic_WUJuU60.woff2') format('woff2'),
        url('/var/www/media/uploads/fonts/gelasio-italic_2vrKwT3.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}




@font-face {
    font-family: 'Heronew';
    src: url('/var/www/media/uploads/fonts/heronew-regular_ROhrp32.woff2') format('woff2'),
        url('/var/www/media/uploads/fonts/heronew-regular_VWf0KdG.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Heronew';
    src: url('/var/www/media/uploads/fonts/heronew-light_bKxw21f.woff2') format('woff2'),
        url('/var/www/media/uploads/fonts/heronew-light_13sgXPI.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Heronew';
    src: url('/var/www/media/uploads/fonts/heronew-medium_4KWMX7P.woff2') format('woff2'),
        url('/var/www/media/uploads/fonts/heronew-medium_uvorX5J.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Heronew';
    src: url('/var/www/media/uploads/fonts/heronew-ultralight_xAW8mqj.woff2') format('woff2'),
        url('/var/www/media/uploads/fonts/heronew-ultralight_vbWL3jN.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}




@import '../_variables';


$global-font-family: heronew, Verdana, Geneva, Helvetica, Tahoma, sans-serif;


$base-heading-font-family: gelasio, Georgia, 'Times New Roman', Times, serif;


$base-button-font-family: Verdana, Geneva, Helvetica, Tahoma, sans-serif;


$base-nav-item-font-family: heronew, heronew, Verdana, Geneva, Helvetica, Tahoma, sans-serif;




@import "../frontend.scss"
