$modal-dialog-background: var(--modal-dialog-background, $global-primary-color);
$modal-dialog-color: var(--modal-dialog-color, $global-color);

@import '~uikit/src/scss/components/modal';

.uk-modal-body{
    color: $modal-dialog-color;
    h1,h2,h3,h4,h5,h6{
        color: $modal-dialog-color;
    }
    .pdf-modal{
        min-height: 600px;
        width: 100%;
    }
}
