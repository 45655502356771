// SECTIONS
$section-default-background: var(--section-default-background, $global-color);
$section-default-color: var(--section-default-color, $global-primary-color);

$section-primary-background: var(--section-primary-background, $global-primary-color);
$section-primary-color: var(--section-primary-color, $global-color);

$section-secondary-background: var(--section-secondary-background, $global-secondary-color);
$section-secondary-color: var(--section-secondary-color, $global-primary-color);

$section-default-color-mode: none;
$section-primary-color-mode: none;
$section-secondary-color-mode: none;

@import '~uikit/src/scss/components/section';

.uk-section-default {
    background-color: $section-default-background;
    color: $section-default-color;
    a:not(.uk-button),
    h1, h2, h3, h4, label {
      color: $section-default-color;
    }
    hr {
        border-top: 1px solid $section-default-color;
    }
}

.uk-section-primary {
    background-color: $section-primary-background;
    color: $section-primary-color;
    a:not(.uk-button),
    h1, h2, h3, h4, label {
        color: $section-primary-color;
    }
    hr {
        border-top: 1px solid $section-primary-color;
    }
}

.uk-section-secondary {
    background-color: $section-secondary-background;
    color: $section-secondary-color;
}

@media (min-width: $breakpoint-large) {
    .uk-section-default\@l {
        background-color: $section-default-background;
        color: $section-default-color;
        a:not(.uk-button),
        h1,
        h2,
        h3,
        h4,
        label {
        color: $section-default-color;
        }
    }

    .uk-section-primary\@l {
        background-color: $section-primary-background;
        color: $section-primary-color;
        a:not(.uk-button),
        h1,
        h2,
        h3,
        h4,
        label {
            color: $section-primary-color;
        }
    }

    .uk-section-secondary\@l {
        background-color: $section-secondary-background;
        color: $section-secondary-color;
    }

}

@media (min-width: $breakpoint-medium) {
    .uk-section-default\@m {
        background-color: $section-default-background;
        color: $section-default-color;
        a:not(.uk-button),
        h1,
        h2,
        h3,
        h4,
        label {
        color: $section-default-color;
        }
    }

    .uk-section-primary\@m {
        background-color: $section-primary-background;
        color: $section-primary-color;
        a:not(.uk-button),
        h1,
        h2,
        h3,
        h4,
        label {
            color: $section-primary-color;
        }
    }

    .uk-section-secondary\@m {
        background-color: $section-secondary-background;
        color: $section-secondary-color;
    }
}

@media (min-width: $breakpoint-small) {
    .uk-section-default\@s {
        background-color: $section-default-background;
        color: $section-default-color;
        a:not(.uk-button),
        h1,
        h2,
        h3,
        h4,
        label {
        color: $section-default-color;
        }
    }

    .uk-section-primary\@s {
        background-color: section-primary-background;
        color: $section-primary-color;
        a:not(.uk-button),
        h1,
        h2,
        h3,
        h4,
        label {
            color: $section-primary-color;
        }
    }

    .uk-section-secondary\@s {
        color: $section-secondary-color;
        background-color: $section-secondary-background;
    }
}
.uk-section-transparent{
    background-color: transparent;
    position: relative;

    &::after {
        content: "";
        opacity: var(--section-transparent-opacity, 0.85);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }
}

.uk-section-transparent.uk-section-default{
    &::after {
        background-color: $section-default-background;
    }
}

.uk-section-transparent.uk-section-primary{
    &::after {
        background-color: $section-primary-background;
    }
}

.uk-section-transparent.uk-section-secondary{
    &::after {
        background-color: $section-secondary-background;
    }
}
