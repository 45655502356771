// GENERIC COLORS
$global-color: $django-color; // MAIN COLOR
$global-primary-color: $django-primary-color; // SFONDO
$global-secondary-color: $django-secondary-color; // TESTI SECODNARI E LINK ATTIVI
$global-inverse-color: $django-inverse-color; // USATO DOVE?
$global-emphasis-color: $django-emphasis-color; // TITOLI
$global-muted-color: $django-muted-color; // COLORE DELLE SEZIONI MUTED

// BACKGROUND COLORS
$global-primary-background: $global-color; // SFONDO PRIMARIO
$global-secondary-background: $global-inverse-color; // SFONDO SECONDARIO
$global-muted-background: $global-inverse-color;

// LINK COLORS
$global-link-color: $global-emphasis-color; // COLORE LINK
$global-link-hover-color: $global-emphasis-color; // COLORE LINK HOVER

// BACKGROUND IMAGES
$site-background-image: $django-site-backgroundimage;
$header-background-image: $django-header-backgroundimage;

//NAV COLORS
$inverse-nav-default-item-color: $global-inverse-color;
$inverse-nav-default-item-hover-color: $global-inverse-color;
$inverse-nav-default-item-active-color: $global-inverse-color;
$inverse-nav-default-sublist-item-color: $global-inverse-color;
$inverse-nav-default-sublist-item-hover-color: $global-inverse-color;
$inverse-nav-default-sublist-item-active-color: $global-inverse-color;

// MARGINS AND PADDINGS
$global-xxsmall-margin: 2px;
$global-xsmall-margin: 5px;
$global-small-margin: 10px;
$global-margin: 20px;
$global-medium-margin: $global-margin;
$global-large-margin: 30px;
$global-xlarge-margin: 50px;
$global-xxlarge-margin: 100px;
$global-xxsmall-padding: $global-xxsmall-margin;
$global-xsmall-padding: $global-xsmall-margin;
$global-small-padding: $global-small-margin;
$global-padding: $global-margin;
$global-medium-padding: $global-padding;
$global-large-padding: $global-large-margin;
$global-xlarge-padding: $global-xlarge-margin;
$global-xxlarge-padding: $global-xxlarge-margin;

// MARGINS FOR MEDIUM
$global-xxsmall-margin-m: 4px;
$global-xsmall-margin-m: 10px;
$global-small-margin-m: 20px;
$global-margin-m: 40px;
$global-medium-margin-m: $global-margin-m;
$global-large-margin-m: 60px;
$global-xlarge-margin-m: 100px;
$global-xxlarge-margin-m: 200px;
$global-xxsmall-padding-m: $global-xxsmall-margin-m;
$global-xsmall-padding-m: $global-xsmall-margin-m;
$global-small-padding-m: $global-small-margin-m;
$global-padding-m: $global-margin-m;
$global-medium-padding-m: $global-padding-m;
$global-large-padding-m: $global-large-margin-m;
$global-xlarge-padding-m: $global-xlarge-margin-m;
$global-xxlarge-padding-m: $global-xxlarge-margin-m;

// MARGINS FOR LARGE AND UP
$global-xxsmall-margin-l: 5px;
$global-xsmall-margin-l: 15px;
$global-small-margin-l: 25px;
$global-margin-l: 50px;
$global-medium-margin-l: $global-margin-l;
$global-large-margin-l: 70px;
$global-xlarge-margin-l: 140px;
$global-xxlarge-margin-l: 200px;
$global-xxsmall-padding-l: $global-xxsmall-margin-l;
$global-xsmall-padding-l: $global-xsmall-margin-l;
$global-small-padding-l: $global-small-margin-l;
$global-padding-l: $global-margin-l;
$global-medium-padding-l: $global-padding-l;
$global-large-padding-l: $global-large-margin-l;
$global-xlarge-padding-l: $global-xlarge-margin-l;
$global-xxlarge-padding-l: $global-xxlarge-margin-l;

// BORDERS
$global-border: $global-color; // BORDER COLOR
$global-primary-border: $global-primary-color; // BORDER COLOR
$global-muted-border: $global-muted-color; // BORDER COLOR
$global-border-secondary: $global-inverse-color;
$global-border-width: 1px;
$global-border-l-width: 2px;

// FONTS
$base-heading-weight: 700;
$global-2xsmall-font-size: .5rem;
$global-xsmall-font-size: .6rem;
$global-small-font-size: .8rem;
$global-medium-font-size: 1rem;
$global-large-font-size: 1.1rem;
$global-xlarge-font-size: 1.25rem;
$global-2xlarge-font-size: 1.5rem;
$global-2xsmall-font-size-m: .5rem;
$global-xsmall-font-size-m: .8rem;
$global-small-font-size-m: 1rem;
$global-medium-font-size-m: 1.25rem;
$global-large-font-size-m: 1.5rem;
$global-xlarge-font-size-m: 2rem;
$global-2xlarge-font-size-m: 2.5rem;
$base-h1-font-size-m: $global-2xlarge-font-size-m;
$base-h2-font-size-m: $global-xlarge-font-size-m;
$base-h3-font-size-m: $global-large-font-size-m;
$base-h4-font-size-m: $global-medium-font-size-m;
$base-h5-font-size-m: $global-small-font-size-m;
$base-h6-font-size-m: $global-xsmall-font-size-m;
$base-h1-font-size: $global-2xlarge-font-size;
$base-h2-font-size: $global-xlarge-font-size;
$base-h3-font-size: $global-large-font-size;
$base-h4-font-size: $global-medium-font-size;
$base-h5-font-size: $global-small-font-size;
$base-h6-font-size: $global-xsmall-font-size;

//HEIGHTS
$height-xsmall-height: 50px;
$height-small-height: 100px;
$height-height: 200px;
$height-large-height: 300px;
$height-xlarge-height: 500px;

// ICONS
$icon-button-hover-color: darken($global-inverse-color, 5%);

// ALERTS
$alert-margin-vertical: 0px;

// BASE
@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";
