// PLACEHOLDER COLOR
$form-background: var(--form-background, #ffffff);
$form-color: var(--form-color, #000000);
$form-focus-color: var(--form-focus-color, $form-color);
$form-placeholder-color: var(--form-placeholder-color, $form-color);
$form-disabled-background: var(--form-disabled-background, $form-background);
$form-disabled-color: var(--form-disabled-color, $form-color);
$form-select-icon-color: var(--form-select-icon-color, $form-color);

$form-radio-background: var(--form-radio-background, $form-background);
$form-radio-disabled-background: var(--form-radio-disabled-background, $form-disabled-background);
$form-radio-disabled-icon-color: var(--form-radio-disabled-icon-color, $form-color);
$form-radio-disabled-border: var(--form-radio-disabled-border, $form-radio-disabled-icon-color);

$form-radio-checked-background: var(--form-radio-checked-background, $form-radio-background);
$form-radio-checked-icon-color: var(--form-radio-checked-icon-color, $global-color);
$form-radio-checked-border: var(--form-radio-checked-border, $form-radio-border);
$form-radio-checked-focus-background: var(--form-radio-checked-focus-background, $form-radio-background);
$form-radio-focus-background: var(--form-radio-focus-background, $form-radio-background);
$form-radio-focus-border: var(--form-radio-focus-border, $form-radio-border);

@import '~uikit/src/scss/components/form';


.uk-input:disabled,
.uk-textarea:disabled {
  &::placeholder {
    color:$form-placeholder-color
  }
}

.ts-control{
    height: $form-height;
}

// FORMS ERRORS
.errorlist {
  @extend .uk-list;
  font-size: 1.2rem;
  line-height: 1;
  margin: 1px;
  padding: 0px;
  >div {
    @extend .uk-alert-danger;
    font-size: $global-small-font-size;
    padding: $global-xsmall-padding;
  }
}
.uk-form-label {
  font-size: $global-large-font-size;
}
.helptext {
  font-size: $global-small-font-size;
  margin-bottom: 10px;
}

.uk-toggle-wrapper{
  position: relative;
  input[type=checkbox] {
  height: 0;
  width: 0;
  display: none;
    // add default box-sizing for this scope
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    &+label {
      box-sizing: border-box;

      &::selection {
        background: none;
      }
    }

    +label {
      top: 0px;
      right:0px;
      outline: 0;
      display: block;
      width: 3em;
      height: 1.5em;
      position: absolute;
      cursor: pointer;
      user-select: none;

      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }

      &:after {
        left: 0;
      }

      &:before {
        display: none;
      }
    }

    &:checked+label:after {
      left: 50%;
    }
    &.uk-form-danger+label {
      border: 1 px solid $global-danger-background !important;
    }
  }

  .helptext{
    padding-right: 50px;
  }

  input[type=checkbox].ios {
    +label {
      background: #fbfbfb;
      border-radius: 2em;
      padding: 2px;
      transition: all .4s ease;
      border: 1px solid #e8eae9;

      &:after {
        border-radius: 2em;
        background: #cfcfcf;
        transition:
          left .3s cubic-bezier(0.175, 0.885, 0.320, 1.275),
          padding .3s ease, margin .3s ease;
        box-shadow:
          0 0 0 1px rgba(0, 0, 0, .1),
          0 4px 0 rgba(0, 0, 0, .08);
      }

      &:hover:after {
        will-change: padding;
      }

      &:active {
        box-shadow: inset 0 0 0 2em #e8eae9;

        &:after {
          padding-right: .8em;
        }
      }
    }

    &:checked+label {
      background: $global-success-background;

      &:active {
        box-shadow: none;

        &:after {
          margin-left: -.8em;
        }
      }
    }
  }

  input[type=checkbox].stripe {
    +label {
      overflow: hidden;
      transform: skew(-10deg);
      backface-visibility: hidden;
      transition: all .2s ease;
      background: #888;

      &:after,
      &:before {
        transform: skew(10deg);
        display: inline-block;
        transition: all .2s ease;
        width: 100%;
        text-align: center;
        position: absolute;
        line-height: 2em;
        font-weight: bold;
        color: #fff;
        text-shadow: 0 1px 0 rgba(0, 0, 0, .4);
      }

      &:after {
        left: 100%;
        content: attr(data-toggle-on);
      }

      &:before {
        left: 0;
        content: attr(data-toggle-off);
      }

      &:active {
        background: #888;

        &:before {
          left: -10%;
        }
      }
    }

    &:checked+label {
      background: $global-success-background;

      &:before {
        left: -100%;
      }

      &:after {
        left: 0;
      }

      &:active:after {
        left: 10%;
      }
    }
  }

  input[type=checkbox].flat {
    +label {
      padding: 2px;
      transition: all .2s ease;
      background: #fff;
      border: 4px solid #f2f2f2;
      border-radius: 2em;

      &:after {
        transition: all .2s ease;
        background: #f2f2f2;
        content: "";
        border-radius: 1em;
      }
    }

    &:checked+label {
      border: 4px solid $global-success-background;

      &:after {
        left: 50%;
        background: $global-success-background;
      }
    }
  }

  input[type=checkbox].flip {
    +label {
      padding: 2px;
      transition: all .2s ease;
      font-family: sans-serif;
      perspective: 100px;

      &:after,
      &:before {
        display: inline-block;
        transition: all .4s ease;
        width: 100%;
        text-align: center;
        position: absolute;
        line-height: 2em;
        font-weight: bold;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        backface-visibility: hidden;
        border-radius: 4px;
      }

      &:after {
        content: attr(data-toggle-on);
        background: $global-success-background;
        transform: rotateY(-180deg);
      }

      &:before {
        background: $global-danger-background;
        content: attr(data-toggle-off);
      }

      &:active:before {
        transform: rotateY(-20deg);
      }
    }

    &:checked+label {
      &:before {
        transform: rotateY(180deg);
      }

      &:after {
        transform: rotateY(0);
        left: 0;
        background: $global-success-background;
      }

      &:active:after {
        transform: rotateY(20deg);
      }
    }
  }
}

@media (min-width: $breakpoint-medium) {
  .uk-form-label {
    font-size: $global-large-font-size-m;
  }
  .helptext {
    font-size: $global-small-font-size-m;
  }
}
