$offcanvas-bar-background: var(--sidenav-bg-color, $global-primary-color);
$offcanvas-bar-color: var(--sidenav-text-color, $global-color);
$offcanvas-bar-disabled-color: var(--sidenav-text-disabled-color, $global-muted-color);
$offcanvas-bar-active-color: var(--sidenav-text-active-color, $global-emphasis-color);
$offcanvas-bar-hover-color: var(--sidenav-text-hover-color, $global-emphasis-color);


@import '~uikit/src/scss/components/offcanvas';
#sidenav.uk-offcanvas .uk-offcanvas-bar .uk-nav-default > li{
    span {
        color: $offcanvas-bar-disabled-color;
    }
    .uk-button {
        color: $offcanvas-bar-color;
    }
    .uk-active .uk-button {
        color: $offcanvas-bar-active-color;
    }
    .uk-button:hover {
        color: $offcanvas-bar-hover-color;
    }
}
